<template>
  <div class="live-container">
    <div class="live-content">
      <div class="live-switch-type">
        <div class="live-switch-type-title"></div>
        <div class="live-switch-type-content">
          <div
            class="live-switch-type-content-li"
            :class="{
              'live-switch-type-content-li-active': current == index,'imgType':item.name.length > 4
            }"
            v-for="(item, index) in typeList"
            :key="item.id"
            @click="handleChangeStatus(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div v-if="count > 0">
        <listItem
          listType="live"
          :list="content"
          v-loading="listLoading"
        ></listItem>

        <!-- 分页组件 -->
        <pagination
          :total="count"
          :page.sync="page"
          :limit.sync="limit"
          @pagination="getList"
        />
      </div>
      <!-- 没有内容显示的页面 -->
      <Nocontent
        v-if="content.length == 0"
        title="暂无文化直播，请持续关注哦～"
      ></Nocontent>
    </div>
  </div>
</template>

<script>
import { queryLiveList } from "@/api/common";
import Pagination from "@/components/Pagination";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
export default {
  data() {
    return {
      count: 0,
      page: 1,
      limit: 6,
      listLoading: true,
      aid: process.env.VUE_APP_AID,
      current: 0,
      cur: 0,
      typeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "正在直播" },
        { id: 2, name: "直播预告" },
        { id: 3, name: "精彩回顾" },
      ],
      content: [],
    };
  },
  components: {
    Pagination,
    ListItem,
    Nocontent,
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = {
        aid: this.aid,
        type: this.current,
        page: this.page,
        page_size: this.limit,
      };
      let self = this;
      this.listLoading = true;
      queryLiveList(params).then((res) => {
        self.listLoading = false;
        this.content = res.data.datalist;
        self.count = res.data.total;
      });
    },
    handleChangeStatus(i) {
      this.page = 1;
      this.current = i;
      this.getList();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/live.scss";
</style>
